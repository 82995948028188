export const environment = {
    env: 'dev',
    locale: 'es',
    webAppUrl: 'https://app.dev01.moneo.clh.no',
    apiUrl: 'https://admin.dev01.moneo.clh.no/api/v1',
    websiteUrl: 'https://websitedev.dev01.moneo.clh.no',
    apiClient: {
        id: 4,
        secret: 'eSPnkZeDf5f32F2X6c2fyC9KqlDZYmHlllUi8AwX'
    },
    sentry: {
        dsn: 'https://0d4cb21e6d0247f39657729aee9b1883@sentry02.cl.clh.no/25',
        tracingOrigins: ['localhost', 'https://app.moneo.comingsoon.no'],
        tracesSampleRate: 0.1,
    },
    analytics: {
        trackingCode: 'UA-162598352-1',
        trackingCodeGA4: 'G-BLCF8JS9WQ',
    },
    google: {
        clientId: '870963938547-8au8hr42qj0tbnb92pdmab3q18j4fv1u.apps.googleusercontent.com'
    },
    facebook: {
        appId: '4043166235696672',
        version: 'v19.0',
    },

    verifyPhone: {
        secondsToWait: 60,
    },
};
