import {NgModule} from '@angular/core';
import {Routes, RouterModule} from '@angular/router';
import {AuthGuard} from "./guards/auth.guard";
import {SecureInnerPagesGuard} from "./guards/secure-inner-pages.guard";
import { UserBelongsActiveReferralCampaignGuard } from './guards/user-belongs-active-referral-campaign.guard';
import {MainComponent} from './layout/main/main.component';
import {LoginComponent} from './pages/login/login.component';
import {ForgotPasswordComponent} from './pages/forgot-password/forgot-password.component';
import { ResetPasswordComponent } from './pages/reset-password/reset-password.component';
import {RegisterComponent} from './pages/register/register.component';
import {HomeComponent} from './pages/home/home.component';
import {ProfileComponent} from './pages/profile/profile.component';
import {CommercesComponent} from './pages/commerces/commerces.component';
import {CommerceDetailComponent} from './pages/commerce-detail/commerce-detail.component';
import {RedeemComponent} from './pages/redeem/redeem.component';
import {TransferComponent} from './pages/transfer/transfer.component';
import {NotFoundComponent} from './errors/not-found/not-found.component';
import {PrivacyComponent} from './pages/privacy/privacy.component';
import {OfferDetailComponent} from './pages/offer-detail/offer-detail.component';
import { GuestOffersComponent } from './pages/guest-offers/guest-offers.component';
import {QuestionnaireComponent} from './pages/questionnaire/questionnaire.component';
import { FaqComponent } from './pages/faq/faq.component';
import {TicketTutorialComponent} from './pages/ticket-tutorial/ticket-tutorial.component'
import {DonationsComponent} from './pages/donations/donations.component';
import { OnboardingComponent } from './pages/onboarding/onboarding.component';
import { AnswersComponent } from './pages/answers/answers.component';
import { VerifyPhoneComponent } from './pages/verify-phone/verify-phone.component';
import { PhoneVerifiedGuard } from './guards/phoneverified.guard'
import { InviteFriendsComponent } from './pages/invite-friends/invite-friends.component';
import { QuestionnairesComponent } from './pages/questionnaires/questionnaires.component';
import { CompleteProfileComponent } from './pages/complete-profile/complete-profile.component';
import { CompleteProfileGuard } from './guards/complete-profile.guard';
import { PhoneRechargesComponent } from './pages/phone-recharges/phone-recharges.component';
import { ArticlesComponent } from "./pages/articles/articles.component";

const routes: Routes = [
    {
        path: '',
        component: MainComponent,
        canActivate: [AuthGuard, PhoneVerifiedGuard],
        canActivateChild: [AuthGuard, PhoneVerifiedGuard],
        children: [
            {
                path: '',
                component: HomeComponent,
                data: {title: 'Home', name: 'home'}
            },
            {
                path: 'home',
                component: HomeComponent,
                data: {title: 'Home', name: 'home'}
            },
            {
                path: 'profile',
                component: ProfileComponent,
                data: {title: 'Profile', name: 'profile'}
            },
            {
                path: 'commerces',
                component: CommercesComponent,
                data: {title: 'Commerces', name: 'commerces'}
            },
            {
                path: 'commerces/:id',
                component: CommerceDetailComponent,
                data: {title: 'Commerce detail', name: 'commerces.show'}
            },
            {
                path: 'commerces/:id/offers',
                component: OfferDetailComponent,
                data: {title: 'Offers details', name: 'commerces.offers'}
            },
            {
                path: 'redeem',
                component: RedeemComponent,
                data: {title: 'Redeem', name: 'redeem'}
            },
            {
                path: 'transfer',
                component: TransferComponent,
                data: {title: 'Transfer', name: 'transfer'}
            },
            {
                path: 'encuestas',
                component: QuestionnairesComponent,
                data: { title: 'Surveys', name: 'surveys'}
            },
            {
                path: 'encuestas/:survey',
                component: QuestionnaireComponent,
                data: { title: 'Survey', name: 'survey'}
            },
            {
                path: 'faq',
                component: FaqComponent,
                data: {title: 'FAQ', name: 'faq'}
            },
            {
                path: 'tutorial',
                component: TicketTutorialComponent,
                data: {title: ' ¡Gana cashback con tus tickets!', name: 'tutorial'}
            },
            {
                path: 'donations',
                component: DonationsComponent,
                data: {title: 'Donar', name: 'donations'}
            },
            {
                path: 'respuestas',
                component: AnswersComponent,
                data: { title: 'Answers', name: 'answers' }
            },
            {
                path: 'verify-phone',
                component: VerifyPhoneComponent,
                data: { title: 'Verify phone', name: 'verify-phone', },
            },
            {
                path: 'invite-friends',
                component: InviteFriendsComponent,
                data: { title: 'Invite friends', name: 'invite-friends' },
                canActivate: [UserBelongsActiveReferralCampaignGuard]
            },
            {
                path: 'complete-profile',
                component: CompleteProfileComponent,
                data: { title: 'Complete profile', name: 'complete-profile', },
                canActivate: [CompleteProfileGuard]
            },
            {
                path: 'recarga-tu-cel',
                component: PhoneRechargesComponent,
                data: { title: 'Recarga tu cel con tu Cashback', name: 'phone-recharges'},
            },
            {
                path: 'articulos/como-convertirte-en-un-comprador-inteligente',
                component: ArticlesComponent,
                data: { title: 'Cómo convertirte en un comprador inteligente', name: 'article'},
            },
        ],
    },

    {
        path: 'auth',
        component: MainComponent,
        children: [
            {
                path: 'login',
                component: LoginComponent,
                canActivate: [SecureInnerPagesGuard],
                data: {title: 'Login', name: 'login'}
            },
            {
                path: 'social/:provider',
                component: LoginComponent,
                canActivate: [SecureInnerPagesGuard],
                data: {title: 'Social login', name: 'login.social'}
            },
            {
                path: 'register',
                component: RegisterComponent,
                canActivate: [SecureInnerPagesGuard],
                data: {title: 'Register', name: 'register'}
            },
            {
                path: 'password/reset',
                component: ForgotPasswordComponent,
                data: {title: 'Forgot password', name: 'password.email'}
            },
            {
                path: 'password/reset/:token',
                component: ResetPasswordComponent,
                data: {title: 'Reset password', name: 'password.reset'}
            }
        ]
    },
    {
        path: 'guest',
        component: MainComponent,
        canActivate: [SecureInnerPagesGuard],
        children: [
            {
                path: '',
                redirectTo: 'offers',
                pathMatch: 'full'
            },
            {
                path: 'offers',
                component: GuestOffersComponent,
                canActivate: [SecureInnerPagesGuard],
                data: {title: 'Cashback offers', name: 'guest.offers'}
            },
        ]
    },
    {
        path: 'onboarding',
        component: MainComponent,
        canActivate: [SecureInnerPagesGuard],
        children: [{
            path: '',
            component: OnboardingComponent,
            data: {title: 'Onboarding', name: 'onboarding'}
        }]
    },
    {
        path: 'privacy',
        component: MainComponent,
        children: [{
            path: '',
            component: PrivacyComponent,
            data: {title: 'Notice of privacy', name: 'privacy'}
        }]
    },
    {
        path: 'not-found',
        component: MainComponent,
        children: [{
            path: '',
            component: NotFoundComponent
        }]
    },
    {
        path: '**',
        component: MainComponent,
        children: [{
            path: '',
            component: NotFoundComponent
        }]
    }
];

@NgModule({
    imports: [RouterModule.forRoot(routes, {scrollPositionRestoration: 'enabled'})],
    exports: [RouterModule]
})
export class AppRoutingModule {}
