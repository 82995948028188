import {BrowserModule, HAMMER_GESTURE_CONFIG, HammerGestureConfig, HammerModule} from '@angular/platform-browser';
import {NgModule, Injectable} from '@angular/core';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {HTTP_INTERCEPTORS, HttpClient, HttpClientModule} from '@angular/common/http';
import {TranslateModule, TranslateLoader} from '@ngx-translate/core';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import {MaterialModule} from './material.module';
import Hammer from 'hammerjs';

import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {MainComponent} from './layout/main/main.component';
import {SidebarComponent} from './layout/sidebar/sidebar.component';
import {HeaderComponent} from './layout/header/header.component';
import {LoginComponent} from './pages/login/login.component';
import {ForgotPasswordComponent} from './pages/forgot-password/forgot-password.component';
import {RegisterComponent} from './pages/register/register.component';
import {HomeComponent} from './pages/home/home.component';
import {CommercesComponent} from './pages/commerces/commerces.component';
import {CommerceDetailComponent} from './pages/commerce-detail/commerce-detail.component';
import {OfferDetailComponent} from './pages/offer-detail/offer-detail.component';
import {RedeemComponent} from './pages/redeem/redeem.component';
import {TransferComponent} from './pages/transfer/transfer.component';
import {ProfileComponent} from './pages/profile/profile.component';
import {TermsConditionsComponent} from './pages/terms-conditions/terms-conditions.component';
import {PrivacyComponent} from './pages/privacy/privacy.component';
import { GuestOffersComponent } from './pages/guest-offers/guest-offers.component';
import {NotFoundComponent} from './errors/not-found/not-found.component';
import {ImageCropperComponent} from './components/image-cropper/image-cropper.component';
import {ConfirmDialogComponent} from './components/confirm-dialog/confirm-dialog.component';
import {AlertDialogComponent} from './components/alert-dialog/alert-dialog.component';
import {AcceptTermsComponent} from './components/accept-terms/accept-terms.component';
import {environment} from './../environments/environment';
import {ServiceWorkerModule} from '@angular/service-worker';
import {AppUpdateService} from './services/app-update.service';
import {GoogleAnalyticsService} from './services/google-analytics.service';

import {TutorialComponent as TutorialComponent} from './pages/tutorial/tutorial.component';
import {QuestionnaireComponent} from './pages/questionnaire/questionnaire.component';
import {NgbCarouselModule} from '@ng-bootstrap/ng-bootstrap';
import { DigitOnlyModule } from '@uiowa/digit-only'

import './extensions/global';
import { OffersComponent } from './components/offers/offers.component';
import { BackButtonDirective } from './directives/back-button.directive';
import { AppInstallService } from './services/app-install.service';
import { FavoriteCommercesComponent } from './components/favorite-commerces/favorite-commerces.component';
import { FieldComponent } from './components/field/field.component';
import { RankingComponent } from './components/ranking/ranking.component';
import { FaqComponent } from './pages/faq/faq.component';
import { BenefitsComponent } from './pages/benefits/benefits.component';
import { SocialLoginComponent } from './components/social-login/social-login.component';
import { TicketTutorialComponent } from './pages/ticket-tutorial/ticket-tutorial.component';
import { DesktopMessageComponent } from './components/desktop-message/desktop-message.component';
import { CookieConsentComponent } from './components/cookie-consent/cookie-consent.component';
import { DonationsComponent } from './pages/donations/donations.component';
import { ResetPasswordComponent } from './pages/reset-password/reset-password.component';
import { OnboardingComponent } from './pages/onboarding/onboarding.component';
import { SliderTutorialComponent } from './components/slider-tutorial/slider-tutorial.component';
import { AnswersComponent } from './pages/answers/answers.component';
import { LatestWinnersComponent } from './components/latest-winners/latest-winners.component';
import { KeyActionsComponent } from './components/key-actions/key-actions.component';
import { SubscribersDialogComponent } from './components/subscribers-dialog/subscribers-dialog.component';
import { VerifyPhoneComponent } from './pages/verify-phone/verify-phone.component';
import { TelInputComponent } from './components/tel-input/tel-input.component';
import { VerificationCodeInputComponent } from './components/verification-code-input/verification-code-input.component';
import { InviteFriendsComponent } from './pages/invite-friends/invite-friends.component';
import { QuestionnaireCardComponent } from './components/questionnaire-card/questionnaire-card.component';
import { QuestionnairesComponent } from './pages/questionnaires/questionnaires.component';
import { AdvertsComponent } from './components/adverts/adverts.component';
import { MaterialDatetimePickerComponent } from './components/material-datetime-picker/material-datetime-picker.component';
import { CompleteProfileComponent } from './pages/complete-profile/complete-profile.component';
import { PhoneRechargesComponent } from './pages/phone-recharges/phone-recharges.component';
import { ErrorHandlerInterceptor } from './interceptors/error-handler-interceptor';

export function HttpLoaderFactory(httpClient: HttpClient) {
    return new TranslateHttpLoader(httpClient);
}

@Injectable()
export class MyHammerConfig extends HammerGestureConfig {
    override buildHammer(element: HTMLElement) {
        let mc = new Hammer(element, {
            touchAction: "pan-y"
        });
        return mc;
    }

    override overrides = <any>{
        swipe: { direction: Hammer.DIRECTION_ALL },
    };
}

@NgModule({
    declarations: [
        AppComponent,
        MainComponent,
        SidebarComponent,
        HeaderComponent,
        ImageCropperComponent,
        ConfirmDialogComponent,
        AlertDialogComponent,
        AcceptTermsComponent,
        LoginComponent,
        ForgotPasswordComponent,
        RegisterComponent,
        HomeComponent,
        ProfileComponent,
        CommercesComponent,
        CommerceDetailComponent,
        OfferDetailComponent,
        RedeemComponent,
        TransferComponent,
        NotFoundComponent,
        TermsConditionsComponent,
        PrivacyComponent,
        TutorialComponent,
        QuestionnaireComponent,
        GuestOffersComponent,
        BackButtonDirective,
        OffersComponent,
        FavoriteCommercesComponent,
        FieldComponent,
        RankingComponent,
        FaqComponent,
        BenefitsComponent,
        SocialLoginComponent,
        TicketTutorialComponent,
        DesktopMessageComponent,
        CookieConsentComponent,
        DonationsComponent,
        ResetPasswordComponent,
        OnboardingComponent,
        SliderTutorialComponent,
        AnswersComponent,
        LatestWinnersComponent,
        KeyActionsComponent,
        SubscribersDialogComponent,
        VerifyPhoneComponent,
        TelInputComponent,
        VerificationCodeInputComponent,
        InviteFriendsComponent,
        QuestionnaireCardComponent,
        QuestionnairesComponent,
        AdvertsComponent,
        MaterialDatetimePickerComponent,
        CompleteProfileComponent,
        PhoneRechargesComponent,
    ],
    imports: [
        FormsModule,
        ReactiveFormsModule,
        HttpClientModule,
        BrowserModule,
        AppRoutingModule,
        BrowserAnimationsModule,
        HammerModule,
        MaterialModule,
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: HttpLoaderFactory,
                deps: [HttpClient]
            }
        }),
        ServiceWorkerModule.register('ngsw-worker.js', { enabled: ['prod', 'staging', 'dev'].indexOf(environment.env) !== -1 }),
        NgbCarouselModule,
        DigitOnlyModule,
    ],
    providers: [
        AppUpdateService,
        AppInstallService,
        GoogleAnalyticsService,
        { provide: HAMMER_GESTURE_CONFIG, useClass: MyHammerConfig },
        { provide: HTTP_INTERCEPTORS, useClass: ErrorHandlerInterceptor, multi: true }
    ],
    bootstrap: [AppComponent]
})
export class AppModule {}
